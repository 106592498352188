<template>
	<div class="crm-patient crm-bg">
		<div class="crm-patient-search">
			<van-search v-model="queryParams.keyword" shape="round" background="transparent" placeholder="请输入用户名/手机号/身份证" @keydown.enter="search" />
			<div class="crm-patient-search-tag">
				<div class="crm-patient-tag__left">
					<span
						class="crm-patient-tag"
						:class="statusList.length == 0 || statusList.length === patient_status.length ? 'active' : ''"
						@click="search('all')"
					>
						全部
					</span>
				</div>
				<div class="crm-patient-tag__right">
					<div class="crm-patient-tag__right-scroll">
						<span
							class="crm-patient-tag"
							:class="statusList.indexOf(item.value) > -1 ? 'active' : ''"
							@click="selectedStatus(item)"
							v-for="(item, index) in patient_status"
							:key="index"
						>
							{{ item.text }}
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class="crm-patient-body">
			<div class="crm-patient-body-scroll">
				<van-pull-refresh v-model="scroll.refreshing" @refresh="refreshScroll">
					<van-list v-model="scroll.loading" :finished="scroll.finished" finished-text="没有更多了" @load="loadScroll">
						<!-- vue for -->
						<div
							class="crm-message-card"
							:class="getClass(item)"
							@click="jumpPage(`/crm/profile/${item.id}`)"
							v-for="(item, index) in list"
							:key="index"
						>
							<div class="crm-message-card-top">
								<div class="crm-message-content">
									<van-row type="flex" justify="space-between">
										<van-col>
											<p>
												<strong class="username">{{ item.name }}
													<template>
													<span v-if="item.tag">
														<van-popover ref="popover" v-model="enhancedList[index].showPopover" trigger="click" placement="top">
                <template #reference>
                  <div @click.stop="togglePopover(index)">

					<van-icon v-if="getList(item.tag) == '危险'" name="info-o" color="#ee0a24" />
					<van-icon v-else-if="getList(item.tag) == '中等'" name="info-o" color="orange" />
					<van-icon v-else-if="getList(item.tag) == '普通'" name="info-o" color="#5f5aa3" />
					<!-- <van-icon name="info-o" v-else-if="enhancedList[index].patientTag" /> -->
                  </div>
                </template>
				<p class="patient_tag_tips">{{ item.tag_dictText }}</p>
              </van-popover>
			</span>
												</template>

												</strong>
												<span class="sex"><SexIcon :sex="item.sex" /></span>
												<span class="age">{{ item.age }}岁</span>
											</p>
										</van-col>
										<van-tag class="" plain size="small" round :color="item.status | statusTag">{{ item.status_dictText }}</van-tag>
									</van-row>
									<p>
										<van-row v-if="item.status == 'FIRST'">
											<van-col :span="12">支付方式：{{ item.payType_dictText }}</van-col>
											<van-col :span="12">建档人：{{ item.createBy }}</van-col>
											<van-col :span="12">建档时间：{{ item.createTime | formatDate("YYYY-MM-DD") }}</van-col>
										</van-row>
										<van-row v-if="item.status == 'CREATED'">
											<van-col :span="12">电话：{{ item.cellphone | arrPhone }}</van-col>
											<van-col :span="12">支付方式：{{ item.payType_dictText }}</van-col>
											<van-col :span="12">住院次数：{{ item.inHospitalTotal }}次</van-col>
											<van-col :span="12">会诊医生：{{ item.consultationDoctor}}</van-col>
											<van-col :span="12">建档时间：{{ item.createTime | formatDate("YYYY-MM-DD") }}</van-col>
										</van-row>
										<van-row v-if="item.status == 'WAIT-AUDIT'">
											<!-- +入院形式 -->

											<van-col :span="11">会诊医生：{{ item.consultationDoctor}}</van-col>
											<van-col :span="13">拟入院时间：{{ item.zzPlanInDate || item.nsInDate | formatDate("YYYY-MM-DD") }}</van-col>
											<van-col :span="11">申请人：{{ item.createBy }}</van-col>
											<van-col :span="13">申请时间：{{ item.createTime | formatDate("YYYY-MM-DD") }}</van-col>
											<van-col :span="24"
												>转诊医院/入院形式：{{ item.zzHospital | convertDict(history_hospital) }}<span v-if="item.zzHospital&&item.zzInType">/</span>
												{{ item.zzInType | convertDict(in_hos_type) }}</van-col
											>
										</van-row>
										<van-row v-if="item.status == 'WAIT-IN'">
											<!-- 病区+床位-->
											<van-col :span="12"
												>病区/床位：{{ item.inWard || item.nsWard }}<span v-if="item.inBed">/</span> {{ item.inBed }}</van-col
											>
											<van-col :span="12">会诊医生：{{ item.consultationDoctor}}</van-col>
											<van-col :span="12">申请人：{{ item.createBy }}</van-col>
											<van-col :span="12">计划入院日：{{ item.zzPlanInDate | formatDate("YYYY-MM-DD") }}</van-col>
										</van-row>
										<van-row v-if="item.status == 'IN-HOSPITAL'">
											<van-col :span="12">医院：{{ item.zzHospital | convertDict(history_hospital) }}</van-col>
											<van-col :span="12">病区/床位：{{ item.inWard }} <span v-if="item.inBed">/</span> {{ item.inBed }}</van-col>
											<van-col :span="12">住院天数：{{ item.inDays }} <span v-if="item.inDays">天</span></van-col>
											<van-col :span="12">会诊医生：{{ item.consultationDoctor}}</van-col>
											<van-col :span="12"
												>入院日期：<span v-if="item.inHosTime == null">-</span>
												<span v-else>
													{{ item.inHosTime | formatDate("YYYY-MM-DD") }}
												</span></van-col>
										</van-row>
										<van-row v-if="item.status == 'OUT'">
											<!-- 病区+床位-->
											<van-col :span="12">出院方式：{{ item.outType_dictText }}</van-col>
											<van-col :span="12">住院天数：{{ item.inDays }} <span v-if="item.inDays">天</span></van-col>
											<van-col :span="12"
												>入院日期：<span v-if="item.inHosTime == null"> - </span>
												<span v-else>
													{{ item.inHosTime | formatDate("YYYY-MM-DD") }}
												</span>
											</van-col>
											<van-col :span="12">会诊医生：{{ item.consultationDoctor}}</van-col>
										</van-row>
									</p>
								</div>
							</div>
							<div class="crm-message-card-bottom">
								<div class="crm-message-msg"></div>
								<div></div>
							</div>
						</div>
						<!-- vue for end -->
					</van-list>
				</van-pull-refresh>
			</div>
		</div>
	</div>
</template>

<script>
import crmMixins from "@/utils/crm-mixins";
import api from "@/request/api";
import TYPES from "@/constant/types";
import SexIcon from "@/components/CRM/SexIcon";

export default {
	name: "crm-patient",
	mixins: [crmMixins],
	components: { SexIcon },
	data() {
		return {
			url: {
				list: api.crmPatientListURL,
			},
			queryParams: {
				column: "update_time",
				order: "desc",
			},
			statusList: [],
			popoverStates: [],
			popoverTimers: [],
			tagArr: [],
		};
	},
	mounted() {
		this.getDataDictionary();
	},
	computed: {
	enhancedList() {
    if (this.popoverStates.length !== this.list.length) {
      this.popoverStates = this.list.map(() => false);
    }
    return this.list.map((item, index) => ({
      ...item,
      showPopover: this.popoverStates[index],
    }));
  }
},
	methods: {
		getList(i) {
			let that = this;
			const tagList = (i || "").split(",");
			const newArr = [];
			let tagStr = "";
			tagList.forEach(function (val, index, arr) {
				that.tagArr.filter((item) => {
					if (item.itemValue == val) {
						newArr.push(item);
					}
				});
				if (newArr.some((item) => item.itemKey == "危险")) {
					that.tagStr = "危险";
				} else if (newArr.some((item) => item.itemKey == "中等")) {
					that.tagStr = "中等";
				} else if (newArr.some((item) => item.itemKey == "普通")) {
					that.tagStr = "普通";
				} else {
					that.tagStr = "无";
				}
			}, 123);
			return that.tagStr;
		},
		getDataDictionary() {
			api.dictItemList({ dictId: "11223344" }).then((res) => {
				if (res.success) {
					this.tagArr = res.result.records;
				} else {
					this.$message.warning(res.message);
				}
			});
		},
		togglePopover(index) {
    // 清除之前的定时器
    if (this.popoverTimers[index]) {
      clearTimeout(this.popoverTimers[index]);
    }

    // 更新 popoverStates 数组
    this.$set(this.popoverStates, index, !this.popoverStates[index]);

    // 如果 Popover 打开，设置定时器在5秒后关闭
    if (this.popoverStates[index]) {
      this.popoverTimers[index] = setTimeout(() => {
        this.$set(this.popoverStates, index, false);
      }, 3000);
    }
  },
		getClass(item) {
			const group = item.groupTag;
			return `card-bg-` + group;
		},
		selectedStatus(item) {
			const index = this.statusList.indexOf(item.value);
			if (index > -1) {
				const status = [...this.statusList];
				status.splice(index, 1);
				this.statusList = [...status];
			} else {
				this.statusList.push(item.value);
			}
			this.queryParams.status = this.statusList.join(",");
			this.search();
		},
		search(info) {
			if (info === "all") {
				this.queryParams.status = "";
			}
			this.loadPage();
		},
	},
};
</script>

<style lang="less">
.crm-patient {
	display: flex;
	flex-direction: column;
	.crm-patient-body {
		flex: 1;
		position: relative;
		overflow: hidden;
		.crm-patient-body-scroll {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow-y: auto;
			> .van-pull-refresh {
				height: 100%;
				min-height: 100%;
				padding: 32px;
				overflow-y: auto;
			}
		}
		.crm-message-content {
			padding-left: 0;
		}
	}
}
.crm-patient-search {
	padding: 32px;
	background: linear-gradient(180deg, #f2f2f2 0%, #ffffff 78.43%);
	box-shadow: 0px 2px 12px rgba(95, 90, 163, 0.25);
	position: relative;
	z-index: 1;
	input {
		font-size: 28px;
	}
	.van-search {
		padding: 0;
		margin-bottom: 24px;
	}
	.van-search__content {
		background: #ffffff;
		box-shadow: 0px 0px 12px rgba(95, 90, 163, 0.25);
	}
	.van-cell {
		align-items: center;
	}
	.van-field__control {
		height: 64px;
		font-weight: 400;
		font-size: 28px;
		line-height: 1.5;
	}
}
.crm-patient-search-tag {
	display: flex;
	flex-direction: row;
	.crm-patient-tag__left {
		width: 100px;
		margin-right: 16px;
		.crm-patient-tag {
			margin: 0;
		}
	}
	.crm-patient-tag__right {
		flex: 1;
		overflow: hidden;
		position: relative;
	}
	.crm-patient-tag {
		white-space: nowrap;
		display: inline-block;
		font-weight: 400;
		font-size: 28px;
		padding: 12px 20px;
		border: 2px solid #8d85ff;
		box-sizing: border-box;
		border-radius: 12px;
		margin: 0 8px;
		&.active {
			background: #8d85ff;
			color: #fff;
		}
	}
	.crm-patient-tag__right-scroll {
		overflow-x: auto;
		position: absolute;
		top: 0;
		left: 0;
		height: 120%;
		width: 100%;
		white-space: nowrap;
	}
}
.card-bg-1 {
	background-color: rgba(255, 85, 0, 0.1);
}
.card-bg-2 {
	background-color: rgba(45, 183, 245, 0.1);
}
.card-bg-3 {
	background-color: rgba(135, 208, 104, 0.1);
}
</style>
