<template>
	<div class="crm-report" v-if="isOpen">

			<van-nav-bar @click-left="onClickLeft"     left-text="返回">
			<template #title
				><span class="bar-title">{{ reportModel.patientName + "-" }} 报告详情</span>
			</template>
			<template #left>

				<!-- <van-icon name="arrow-left" size="18" /> -->
			</template>
		</van-nav-bar>
		<div class="crm-report-body">
			<div>
				<van-cell>
					<van-row class="crm-basic">
						<van-col class="crm-report-title" :span="24">基本信息</van-col>
						<van-col class="crm-report-text" :span="12">姓名：{{ reportModel.patientName }}</van-col>
						<van-col class="crm-report-text" :span="12">性别：{{ reportModel.patient.sex | convertDict(sex) }}</van-col>
						<van-col class="crm-report-text" :span="12">影像号：-------</van-col>
						<van-col class="crm-report-text" :span="12">检查时间：{{ reportModel.checkTime | formatDate("YYYY-MM-DD") }}</van-col>
						<van-col class="crm-report-text" :span="12">检验科室：{{ reportModel.department }}</van-col>
						<van-col class="crm-report-text" :span="12">病区：{{ reportModel.inpatientArea }}</van-col>
					</van-row>
					<van-row class="crm-basic">
						<van-col class="crm-report-title" :span="24">检查信息</van-col>
						<van-col class="crm-report-text table-head" :span="12">项目</van-col>
						<van-col class="crm-report-text table-head" :span="4">结果</van-col>
						<van-col class="crm-report-text table-head" :span="8">参考值</van-col>

						<ol>
							<li class="crm-report-flex" v-for="(item, key) in reportModel.crmVwSfYjjgkList" :key="key">
								<van-col class="crm-report-text crm-report-table-text" :span="12">{{ item.xmmc }}</van-col>
								<van-col class="crm-report-text crm-report-table-text" :span="5"
									>{{ item.xmjg }}
									<van-icon color="green" v-if="Number(item.xmjg) < `${item.jgckz.split('～').map(Number)[0]}`" name="down" />
									<van-icon
										class="ckz-icon"
										color="red"
										v-else-if="Number(item.xmjg) > `${item.jgckz.split('～').map(Number)[1]}`"
										name="down"
									/>
								</van-col>
								<van-col class="crm-report-text crm-report-table-text" :span="7">{{ item.jgckz }} </van-col>
							</li>
						</ol>
					</van-row>

					<van-row class="crm-basic">
						<van-col class="crm-report-text" :span="24">收样日期：----------</van-col>
						<van-col class="crm-report-text crm-report-time" :span="24"
							>报告时间：{{ reportModel.reportDate | formatDate("YYYY-MM-DD HH:mm") }}</van-col
						>
					</van-row>
				</van-cell>
			</div>
		</div>

	</div>
</template>

<script>
import crmMixins from "@/utils/crm-mixins";
import api from "@/request/api";
import TYPES from "@/constant/types";
import SexIcon from "@/components/CRM/SexIcon";
import dayjs from "dayjs";
import { Notify } from "vant";

export default {
	name: "crm-report",
	mixins: [crmMixins],
	components: { SexIcon },
	data() {
		return {
			reportModel: {
				patient: {
					sex: '', // 或者其他默认值
				},
				checkTime: "",
				patientName: "",
				department: "",
				inpatientArea: "",
				crmVwSfYjjgkList:{}

			},
			isOpen: true,
		};
	},
	mounted() {
		this.reportModel = this.$route.params.params;
	},
	methods: {
		show(record) {
			console.log(record, "record");
			this.reportModel = record;
			this.isOpen = true;
		},
		onClickLeft() {
			this.$router.go(-1);
			// this.isOpen = false;
			// this.$emit('ok', true)
		},
	},
};
</script>

<style lang="less">
.crm-report-flex{
	display: flex;
    width: 100%;
	align-items: center;
}
.ckz-icon {
	transform: rotate(180deg);
}
.crm-report{
	// position: absolute;
    // top: -2rem;
    // left: 0;
}
.crm-report-body {
	width: 100vw;
	height: 100vh;
	overflow: auto;
	padding-bottom: 10vh;
	.crm-report-title {
		font-weight: 600;
		font-size: 0.853rem;
		line-height: 1.8rem;
		text-align: left;
		margin-bottom: 0.2rem;
	}
	.crm-report-text {
		font-weight: 400;
		font-size: 0.747rem;
		line-height: 1.04rem;
		color: #333333;
		margin-bottom: 0.24rem;
	}
	.crm-report-table-text {
		font-size: 0.707rem;
	}
	.crm-basic {
		margin: 0.533rem;
		// padding: 0.748rem;
	}
	.crm-basic-report {
		.crm-report-text {
			margin-bottom: 0.54rem;
		}
	}
	.crm-report-btn {
		height: 2.56rem;
		background: #5f5aa3;
		border-radius: 0.23rem;
		color: #fff;
		font-size: 0.853rem;
		line-height: 1.067rem;
		white-space: nowrap;
	}
	.crm-report-time {
		margin-bottom: 1rem;
	}
	.table-head {
		font-weight: 600;
	}
}
.little-title {
	font-weight: 400;
}

</style>
